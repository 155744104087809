/*
  i18nService
  Responsible for:
    - Setting up i18n and controlling plugin localization
    - Rendering the LanguageSelector if specified on the partner level
*/

import { Component } from 'react';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'i18next';
import moment from 'moment';

// Each moment locale needs to be independently loaded
import 'moment/locale/fr';
import 'moment/locale/sv';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/nl';

import './i18n';
import { changeI18n as changeI18nAction } from '../redux/plugins/actions';
import Select from '../shared/components/form/Select';

import { Theme } from '../shared/styles';
import { ENGLISH_LANGUAGE_CODE } from './constants';

import { updateFilters } from '../redux/experiences/actions';
import { languageHeader, languageSelector } from '../shared/utils/staticCssClasses';

const LanguageHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 25px;
  background: ${Theme.colors.lightBackground};
  width: 100%;
`;

const LanguageSelector = styled(Select)`
  max-width: 180px;
  width: 100%;
`;

// Moment config for better humanization
// https://github.com/moment/moment/issues/348#issuecomment-629836811
moment.relativeTimeRounding(t => {
  const DIGITS = 2; // like: 2.56 minutes
  return Math.round(t * 10 ** DIGITS) / 10 ** DIGITS;
});
moment.relativeTimeThreshold('y', 365);
moment.relativeTimeThreshold('M', 12);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('d', 31);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 0);

class I18nServiceComponent extends Component {
  // Dropdown helpers =====
  languageValue = () => {
    const { i18n: i18nValue } = this.props;
    return this.getLanguageOption(i18nValue);
  };

  getLanguageOption = value => {
    let option = this.languageOptions().find(language => language.value === value);
    if (!option) {
      option = this.languageOptions().find(language => language.value === 'en-US');
    }
    return option;
  };

  languageOptions = () => {
    const { plugin } = this.props;
    const languageNames = {
      'en-US': 'English',
      'es-ES': 'Español',
      'sv-SE': 'Svenska',
      'fr-CA': 'Français (Canada)',
      'fr-FR': 'Français (France)',
      'de-DE': 'Deutsch',
      'nl-NL': 'Nederlands',
      'el-GR': 'Ελληνικά',
      'it-IT': 'Italiano',
      'zh-CN': '简体中文',
      'zh-TW': '繁体中文'
    };
    const englishOption = {
      value: ENGLISH_LANGUAGE_CODE,
      label: languageNames[ENGLISH_LANGUAGE_CODE]
    };
    if (!plugin) {
      return [englishOption];
    }
    const { languageSettings } = plugin;
    const { enabledLanguages } = languageSettings;
    const cleanEnabledLanguages = this.cleanEnabledLanguages(enabledLanguages);

    const options = cleanEnabledLanguages
      ? cleanEnabledLanguages.map(language => ({
          value: language,
          label: languageNames[language]
        }))
      : [];
    return [englishOption, ...options];
  };
  // ===== Dropdown helpers

  onLanguageChange = language => {
    const { changeI18n, dispatch } = this.props;

    // Update redux state + moment global locale
    const lang = language.value;

    changeI18n({ i18n: lang });
    moment.locale(lang);
    i18n.changeLanguage(lang);

    dispatch(updateFilters({ lang }));
  };

  /* By default, Ruby will add a blank option in a list curated by checkboxes
   as is the case in the Plugin>Enabled Languages section of the Admin portal.
   We want to remove this space to ensure a clean dropdown menu.
   If we find that we need this cleanup in multiple spots,
   its best to move this logic to the BE */
  cleanEnabledLanguages = enabledLanguages =>
    enabledLanguages ? enabledLanguages.filter(enabledLanguage => enabledLanguage) : [];

  render() {
    const {
      plugin: { languageSettings }
    } = this.props;
    if (!languageSettings) {
      return null;
    }
    const { enabledLanguages } = languageSettings;
    const cleanEnabledLanguages = this.cleanEnabledLanguages(enabledLanguages);
    return (
      cleanEnabledLanguages.length > 0 && (
        <LanguageHeader className={languageHeader} data-testid="language-dropdown">
          <LanguageSelector
            className={languageSelector}
            value={this.languageValue()}
            options={this.languageOptions()}
            onChange={this.onLanguageChange}
            height="44px"
            isSearchable={false}
            boldSelected
          />
        </LanguageHeader>
      )
    );
  }
}

const mapStateToProps = ({ plugins }) => {
  return {
    i18n: plugins.i18n,
    plugin: plugins.plugin
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({ changeI18n: changeI18nAction }, dispatch)
});

export const I18nService = connect(mapStateToProps, mapDispatchToProps)(I18nServiceComponent);
